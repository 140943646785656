<template>
  <div class="wrap">
    <Navbar :title="$t('My.PersonalInformation.Password.title')"></Navbar>
    <div class="from">
      <div class="from_item">
        <div class="title">
        {{ $t('Password.setPassword') }}
      </div>
      <van-field label-align="right" label-width="7.5rem" :border="false" colon v-model="newpass"
        :placeholder="$t('Password.enterPassword')" />
      </div>
      <div class="from_item">
        <div class="title">
          {{ $t('Password.confirmPassword') }}
        </div>
        <van-field label-align="right" label-width="7.5rem" :border="false" colon v-model="confirmpass"
          :placeholder="$t('Password.enterConfirmPassword')" />
      </div>
      <div class="button-confirm">
        <van-button size="normal" type="default" block @click="ButtomEvent()">{{
          $t('My.PersonalInformation.Name.Confirm')
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import { getUserInfoNew } from '@/api'
import Navbar from "@/components/JNav";
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Toast,
} from 'vant'

export default {
  name: 'Name',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Toast.name]: Toast,
    Navbar
  },
  data() {
    return {
      code: '112233',
      newpass: '',
      confirmpass: '',
      userInfo: {}
    }
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      if (this.newpass == '') {
        this.$toast(this.$t('Password.enterPassword'));
        return false;
      }
      if (this.newpass != this.confirmpass) {
        this.$toast(this.$t('Password.doubbleDiff'));
        return false;
      }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      this.$api.editPwd(
        {
          code: this.code,
          newpass: this.newpass,
        },
        (res) => {
          Toast.clear();
          Toast(res.msg);
          setTimeout(() => {
            this.$router.replace({ name: "login" });
          }, 800);
        },
        (err) => {
          Toast.clear();
          Toast(err.data.msg);
        }
      );
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      this.$api.getUserInfo(res => {
        this.Name = res.realname;
        this.userInfo = res;
        Toast.clear();
      }, err => {
        Toast.clear();
      })
    }
  },
  created() {
    this.getUserInfo();
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;

  .from {
    padding: calc(20rem / 16) calc(15rem / 16) calc(15rem / 16) calc(15rem / 16);
    .from_item{
      margin-bottom: calc(20rem / 16);
    }
    .title {
      font-size: calc(18rem / 16);
      font-weight: 500;
      color: var(--textColor);
      margin-bottom: calc(15rem / 16);
    }

  }

  /deep/ .van-cell {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: var(--light);
    border: 1px solid transparent;
    outline: 0;
    display: block;
    box-sizing: border-box;
    height: calc(52rem / 16);
    display: flex;
    align-items: center;
    font-size: calc(16rem / 16);
    font-weight: 500;
    line-height: calc(22rem / 16);
    color: var(--light);
    border-radius: inherit;
    padding: calc(6rem / 16) calc(15rem / 16);
    box-sizing: border-box;

    input {
      color: var(--textColor);
    }

    input::placeholder {
      color: var(--colors);
    }

  }

  .button-confirm {
    width: 100%;
    height: calc(84rem / 16);
    margin-top: calc(50rem / 16);

    .van-button {
      background: var(--red);
      border: none;
      height: calc(54rem / 16);
      font-size: calc(18rem / 16);
      font-weight: 400;
      color: var(--light);
      border-radius: calc(10rem / 16);
    }
  }
}
</style>